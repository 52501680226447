<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmaqipoints.formtitle')">
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-text-field :label="$t('pmaqipoints.name')" v-model="model.name" toplabel required maxlength="150"></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								v-if="isSuperAdmin"
								attach="pmorganizationid"
								:label="this.$t('pmaqipoints.organization')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmorganization"
								:modelFormMapping="{ pmorganizationid: 'pmorganizationid' }"
								:itemsToSelect="organizationItemsToSelect"
								itemValue="pmorganizationid"
								itemText="name"
							></pui-select>
							<pui-text-field
								v-else
								:label="$t('pmaqipoints.organization')"
								toplabel
								disabled
								required
								v-model="organizationName"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								attach="pmareaid"
								:label="this.$t('pmaqipoints.area')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmarea"
								:modelFormMapping="{ pmareaid: 'pmareaid' }"
								:itemsToSelect="areaItemsToSelect"
								:fixedFilter="areaFixedFilter"
								itemValue="pmareaid"
								itemText="name"
							></pui-select>
						</v-flex>
					</v-layout>
					<pui-field-set :title="$t('pmaqipoints.location')">
						<v-layout wrap>
							<v-flex xs12 md6>
								<pui-text-field :label="$t('pmaqipoints.longitude')" v-model="model.longitude" toplabel maxlength="150">
								</pui-text-field>
							</v-flex>
							<v-flex xs12 md6>
								<pui-text-field :label="$t('pmaqipoints.latitude')" v-model="model.latitude" toplabel maxlength="150">
								</pui-text-field>
							</v-flex>
						</v-layout>
					</pui-field-set>
					<v-layout wrap>
						<v-flex xs12>
							<pui-text-area
								v-model="model.description"
								:label="$t('pmaqipoints.description')"
								maxlength="250"
								toplabel
							></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('pmaqipoints.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

//import constants from '@/utils/constants';
import { isSuperAdmin } from '../../api/common';

export default {
	name: 'pmaqipointsform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'pmaqipoints',
			model: 'pmaqipoints',
			isSuperAdmin: false,
			areaFixedFilter: null
		};
	},
	computed: {
		organizationItemsToSelect() {
			return [{ pmorganizationid: this.model.pmorganizationid }];
		},
		organizationName() {
			return this.userProperties.organizationname;
		},
		areaItemsToSelect() {
			return [{ pmareaid: this.model.pmareaid }];
		}
	},
	mounted() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		this.areaFixedFilter = {
			groups: [],
			groupOp: 'and',
			rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }]
		};
	},
	updated() {
		if (this.model) {
			if (!this.modelLoaded || !this.model.pmorganizationid) {
				this.modelLoaded = true;
				this.model.pmorganizationid = this.userProperties.organizationid;
			}
		}
	},
	methods: {}
};
</script>
